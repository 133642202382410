import Link from 'next/link';
import React from 'react';

const WrapperLink = ({
  href,
  className,
  children,
}: {
  href: string;
  className: string;
  children: React.ReactNode;
}) => {
  return (
    <div className={className} style={{ cursor: 'pointer' }}>
      <Link href={href}>
        <a>{children}</a>
      </Link>
    </div>
  );
};

export default WrapperLink;
