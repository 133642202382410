import React from 'react';
import Image from 'next/image';

import styles from './Navbar.module.css';
import WrapperLink from 'components/WrapperLink';
import ButtonBig from 'components/Button/Button';
import {
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import { GiHamburgerMenu } from 'react-icons/gi';

type TLink = {
  title: string;
  href: string;
};

function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const extraLinks = [
    { title: 'Get Started', href: '/student/signup' },
    { title: 'Log In', href: '/student/login' },
  ];

  return (
    <div role="banner" className={`${styles.header} ${styles['w-nav']}`}>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody minH={'full'}>
            <Flex
              m={'auto'}
              h={'full'}
              gap="1.3em"
              flexDir={'column'}
              pt="5em"
              alignItems={'flex-start'}
            >
              <NavLinks extraLinks={extraLinks} />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <div
        className={`${styles['container-default']} ${styles['header-container']} ${styles['w-container']}`}
      >
        <div className={styles['header-wrapper']}>
          <div
            className={`${styles['split-content']} ${styles['header-right']}`}
          >
            <WrapperLink
              href="/"
              className={`${styles.brand} ${styles['w-nav-brand']} ${styles['w--current']}`}
            >
              <Image
                width={250}
                height={50}
                src={'/navbar/logo.png'}
                alt="logo"
                className={styles['header-logo']}
                style={{ height: '4em' }}
              />
            </WrapperLink>
          </div>

          <NavLinks />
          <IconButton
            aria-label="menu"
            alignContent={'center'}
            // eslint-disable-next-line react/no-children-prop
            children={<GiHamburgerMenu />}
            display={['flex', 'flex', 'none']}
            onClick={onOpen}
          />
          <ButtonBig
            className="button-primary-header button-2-buttons-copy w-button"
            title="Get Started"
            href="/student/signup"
          />
          <ButtonBig
            className="button-secondary-header header hidden w-button"
            title="Log In"
            href="/student/login"
          />
        </div>
      </div>
    </div>
  );
}

const NavLinks = ({ extraLinks = [] }: { extraLinks?: TLink[] }) => {
  const navLinks = [
    { title: 'Home', href: '/' },
    { title: 'About', href: '/about' },
    { title: "FAQ's", href: '/faqs' },
    { title: 'Blog', href: '/blog' },
    { title: 'Free Resources', href: '/free-resources' },
    ...extraLinks,
  ];

  return (
    <nav
      role="navigation"
      className={`${styles['nav-menu']} ${styles['w-nav-menu']}`}
      data-collapse="medium"
    >
      <ul role="list" className={styles['header-navigation']}>
        {navLinks.map((link, index) => (
          <li key={index} className={styles['nav-item-wrapper']}>
            {link && (
              <WrapperLink
                href={link.href || ''}
                aria-current="page"
                className={`${styles['nav-link']} ${styles['w--current']}`}
              >
                {link.title}
              </WrapperLink>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
