import Image from 'next/image';
import WrapperLink from 'components/WrapperLink';

import Logo from '../../public/footer/logo.png';
import newsletterSvg from '../../public/footer/newsletter.svg';
import styles from './Footer.module.css';

// import NewsletterForm from 'components/newsletterSection/NewsletterForm';

export default function Footer() {
  const footerLinks = [
    {
      title: 'Home',
      href: '/',
    },
    {
      title: 'About',
      href: '/about',
    },
    {
      title: "FAQ's",
      href: '/faqs',
    },
    {
      title: 'Contact Us',
      href: '/faqs',
    },
    {
      title: 'Blog',
      href: '/blog',
    },
  ];
  const socialLinks = [
    {
      title: 'Facebook',
      href: 'https://www.facebook.com/',
      faIcon: 'fab fa-facebook',
    },
    {
      title: 'Twitter',
      href: 'https://twitter.com/',
      faIcon: 'fab fa-twitter',
    },
    {
      title: 'Instagram',
      href: 'https://www.instagram.com/',
      faIcon: 'fab fa-instagram',
    },

    {
      title: 'LinkedIn',
      href: 'https://www.linkedin.com/',
      faIcon: 'fab fa-linkedin',
    },
    {
      title: 'YouTube',
      href: 'https://www.youtube.com/',
      faIcon: 'fab fa-youtube',
    },
  ];
  return (
    <footer className={styles.footer}>
      <div
        className={`${styles['container-default']} ${styles['w-container']}`}
      >
        <div className={styles['footer-content-top']}>
          <WrapperLink
            href={'/'}
            aria-current="page"
            className={`${styles['footer-logo-container']} ${styles['w-inline-block']} ${styles['w--current']}`}
          >
            <Image
              src={Logo}
              height={57}
              width={264}
              alt="logo"
              className={styles['footer-logo']}
            />
          </WrapperLink>
          <p
            className={`${styles.paragraph} ${styles['footer-content-top-paragraph']}`}
          >
            Expert Tutors: Improving Grades, Increasing Confidence.
          </p>
        </div>
        <div className={`${styles.divider} ${styles['footer-top']}`}></div>
        <div className={styles['footer-content']}>
          <div className={styles['footer-newsletter-content']}>
            <div className={`${styles['footer-newsletter-content-top']}`}>
              <div
                className={`${styles.image} ${styles['footer-newsletter-icon']}`}
              >
                <Image
                  width={72}
                  height={72}
                  src={newsletterSvg}
                  alt="Newsletter Icon"
                />
              </div>
              <div className={`footer-newsletter-title-wrapper`}>
                <h2
                  className={`${styles.title} ${styles['h3-size']} ${styles['footer-newsletter']}`}
                >
                  Subscribe to our newsletter
                </h2>
                <p
                  className={`${styles.paragraph} ${styles['footer-newsletter']}`}
                >
                  Free materials, resources, and past papers.
                </p>
              </div>
            </div>
            <div
              className={`${styles['footer-newsletter-form-block']} ${styles['w-form']}`}
            >
              <form
                id="wf-form-Footer-Newsletter-Form"
                name="wf-form-Footer-Newsletter-Form"
                data-name="Footer Newsletter Form"
                method="get"
                className={`${styles['footer-newsletter-form']}`}
                data-wf-page-id="63cd9e4c70e278c63c37b530"
                data-wf-element-id="e95e173c-4f32-8d11-c315-18cd5f825998"
              >
                <input
                  className={`${styles.input} ${styles['footer-newsletter']} ${styles['w-input']}`}
                  maxLength={256}
                  name="email"
                  data-name="Email"
                  placeholder="Enter your email"
                  type="email"
                  id="email"
                  required
                />
                <input
                  type="submit"
                  data-wait="Please wait..."
                  className={`${styles['button-primary-header']} ${styles['footer-newsletter']} ${styles['w-button']}`}
                  value="Submit"
                />
              </form>
              <div
                className={`${styles['success-message']} ${styles['w-form-done']}`}
              >
                <div>Thanks for joining our newsletter.</div>
              </div>
              <div
                className={`${styles['error-message']} ${styles['w-form-fail']}`}
              >
                <div>Oops! Something went wrong.</div>
              </div>
            </div>
            {/* <NewsletterForm /> */}
          </div>
          <div className={`${styles['footer-nav-main-wrapper']}`}>
            <div className={`${styles['footer-nav-wrapper']} ${styles.pages}`}>
              <div className={styles['footer-nav-title']}>Pages</div>
              <div className={`${styles['footer-nav-content']}`}>
                <ul role="list" className={styles['footer-nav']}>
                  {footerLinks.map((link) => (
                    <li key={link.href} className={styles['footer-nav-item']}>
                      <WrapperLink
                        href={link.href}
                        aria-current="page"
                        className={`${styles['footer-nav-link']} ${styles['w--current']}`}
                      >
                        {link.title}
                      </WrapperLink>
                    </li>
                  ))}
                  <li className={styles['footer-nav-item']}></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.divider} ${styles['footer-bottom']}`}></div>
        <div className={styles['footer-content-bottom']}>
          {socialLinks.map((link, index) => (
            <WrapperLink
              key={index}
              href={link.href}
              className={styles['footer-social-media-link']}
            >
              <i className={link.faIcon}></i>
            </WrapperLink>
          ))}
        </div>
      </div>
    </footer>
  );
}
