import Link from 'next/link';
import React from 'react';
import styles from './Button.module.css';

const ButtonBig = ({
  className,
  title,
  href,
}: {
  className?: string;
  title: string;
  href?: string;
}) => {
  let btnClass = '';

  className &&
    className.split(' ').forEach((extraClass) => {
      if (styles[extraClass]) {
        btnClass += ` ${styles[extraClass]}`;
      }
    });

  return (
    <>
      {href ? (
        <Link href={href} passHref>
          <a className={btnClass}>{title}</a>
        </Link>
      ) : (
        <div className={btnClass}>{title}</div>
      )}
    </>
  );
};

export default ButtonBig;
